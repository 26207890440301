import { useMemo } from "react";
import { ContactListSortConfig, ContactObj } from "./ContactModel";
import { Column, ColumnInstance, useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import Pagination from "../../../UI/Pagination";
import { ContactListPaginationConfig } from "./ContactListModal";
import { useTranslation } from "react-i18next";

interface Props {
  data: Array<ContactObj>;
  totalDataCount: number;
  paginationConfig: ContactListPaginationConfig;
  setPaginationConfig: React.Dispatch<React.SetStateAction<ContactListPaginationConfig>>;
  onDataUpdated: (updatedData: Array<ContactObj>) => void;
  setSortConfig: React.Dispatch<React.SetStateAction<ContactListSortConfig | undefined>>;
  sortConfig?: ContactListSortConfig;
}

export default function ContactsTable(props: Props) {
  const { t } = useTranslation();

  const columns: Column<ContactObj>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessor: "fullName",
        Header: ({ column }) =>
          Header({
            column: column,
            sortConfig: props.sortConfig,
            setSortConfig: props.setSortConfig,
            hasCheckMark: true,
            allItemsHasChecked: allItemsChecked(),
            onColumnChecked: (checked: boolean) =>
              props.onDataUpdated([...props.data.map((item) => ({ ...item, isSelected: checked }))]),
          }),
        Cell: ({ value, row }) => {
          return (
            <div>
              <input
                type={"checkbox"}
                checked={row.original.isSelected}
                onChange={(event) => {
                  props.onDataUpdated([
                    ...props.data.map((item) =>
                      item.id === row.original.id && item.childName === row.original.childName
                        ? { ...item, isSelected: event.target.checked }
                        : item
                    ),
                  ]);
                }}
                className="mx-4 cursor-pointer"
              />
              {value}
            </div>
          );
        },
      },
      {
        header: t("phone_number"),
        accessor: "phone",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
        Cell: ({ value }) => {
          return <label>{value.translateDigits()}</label>;
        },
      },
      {
        header: t("clinic"),
        accessor: "clinics",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
        Cell: ({ value }) => {
          return <label>{value.join(", ")}</label>;
        },
      },
      {
        header: t("type"),
        accessor: "role",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
        Cell: ({ row }) => {
          return <label>{row.original.roleString}</label>;
        },
      },
      {
        header: t("child_name"),
        accessor: "childName",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
      },
      {
        header: t("child_age"),
        accessor: "childDays",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
        Cell: ({ row }) => {
          return (
            <label>
              {row.original.childDays < 7
                ? t("birth")
                : `${Math.floor(row.original.childDays / 7)} ${t("weeks")}`.translateDigits()}
            </label>
          );
        },
      },
      {
        header: t("child_gender"),
        accessor: "gender",
        Header: ({ column }) => Header({ column, sortConfig: props.sortConfig, setSortConfig: props.setSortConfig }),
      },
    ],
    [t, props.data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ContactObj>({
    columns: columns,
    data: props.data,
  });

  const allItemsChecked = () =>
    props.data.length > 0 && props.data.find((item) => Boolean(!item.isSelected)) === undefined;

  return (
    <div className="flex w-full flex-col text-black41 px-6" dir="ltr">
      <div className="h-[400px] overflow-auto">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    className={`td-firefox text-sm text-gray-41 border-b-[1px] py-[20px]`}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  className="text-sm border-b-[1px] cursor-pointer hover:bg-slate-100"
                  onClick={() => {
                    props.onDataUpdated([
                      ...props.data.map((item) =>
                        item.id === row.original.id && item.childName === row.original.childName
                          ? { ...item, isSelected: !row.original.isSelected }
                          : item
                      ),
                    ]);
                  }}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="py-4 whitespace-nowrap">
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          dropdownKeys={["5", "10", "20"]}
          previousPage={() =>
            props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex - 1 }))
          }
          nextPage={() => props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: preValue.pageIndex + 1 }))}
          setPageSize={(pageSize) => props.setPaginationConfig((preValue) => ({ ...preValue, pageSize: pageSize }))}
          gotoPage={(pageNum) =>
            props.setPaginationConfig((preValue) => ({ ...preValue, pageIndex: pageNum as number }))
          }
          pageCount={Math.ceil(props.totalDataCount / props.paginationConfig.pageSize)}
          pageIndex={props.paginationConfig.pageIndex}
          pageSize={props.paginationConfig.pageSize}
          pageLength={props.data.length}
          totalLength={props.totalDataCount}
        />
      </div>
    </div>
  );
}

interface HeaderProps {
  column: ColumnInstance<ContactObj>;
  sortConfig?: ContactListSortConfig;
  setSortConfig?: React.Dispatch<React.SetStateAction<ContactListSortConfig | undefined>>;
  hasCheckMark?: boolean;
  allItemsHasChecked?: boolean;
  onColumnChecked?: (checked: boolean) => void;
}

function Header(props: HeaderProps) {
  const mapColumnNameToColumnSortKey = (columnId: string) => {
    if (columnId === "fullName") return "full_name";
    if (columnId === "phone") return "phone_number";
    if (columnId === "type") return "role";
    if (columnId === "clinics") return "clinic_count";
    if (columnId === "childName") return "child_name";
    if (columnId === "childDays") return "child_days";

    return columnId.toLowerCase();
  };

  return (
    <div className="flex items-center">
      {props.hasCheckMark && (
        <input
          type={"checkbox"}
          checked={props.allItemsHasChecked}
          onChange={(event) => props.onColumnChecked?.(event.target.checked)}
          className="mx-4 cursor-pointer"
        />
      )}
      <h3>{props.column.render("header")}</h3>
      <div className={`flex flex-col mx-4`}>
        <FontAwesomeIcon
          className={`text-sm cursor-pointer ${
            props.sortConfig?.column === props.column.id && props.sortConfig?.sortType === "Ascending"
              ? "text-black41"
              : "text-gray-df"
          }`}
          icon={faAngleUp}
          onClick={() =>
            props.setSortConfig?.({
              column: props.column.id,
              sortKey: mapColumnNameToColumnSortKey(props.column.id),
              sortType: "Ascending",
            })
          }
        />
        <FontAwesomeIcon
          className={`text-sm cursor-pointer ${
            props.sortConfig?.column === props.column.id && props.sortConfig?.sortType === "Descending"
              ? "text-black41"
              : "text-gray-df"
          }`}
          icon={faAngleDown}
          onClick={() =>
            props.setSortConfig?.({
              column: props.column.id,
              sortKey: mapColumnNameToColumnSortKey(props.column.id),
              sortType: "Descending",
            })
          }
        />
      </div>
    </div>
  );
}
