import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faClock } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../UI/Tooltip";

interface Props {
  receivers: Array<string>;
  futureReceivers: Array<string>;
}

export default function ReceiverCell(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center">
      <Tooltip
        containerClassname="flex items-center"
        content={`${t("potential_future_recipients")}: ${props.futureReceivers.length.translateDigits()}`}
        position={{
          x: 0,
          y: 30,
        }}
      >
        <label>{`${props.futureReceivers.length.translateDigits()} ${t("recipients")}`}</label>
        <FontAwesomeIcon className="text-orange-primary ml-2 p-[6px] hover:bg-gray-f2 rounded-[3px]" icon={faClock} />
      </Tooltip>

      <Tooltip
        containerClassname="flex items-center"
        content={`${t("confirmed_recipients")}: ${props.receivers.length.translateDigits()}`}
        position={{
          x: 0,
          y: 30,
        }}
      >
        <label>{`${props.receivers.length.translateDigits()} ${t("recipients")}`}</label>
        <FontAwesomeIcon
          className="text-green-primary ml-2 p-[6px] hover:bg-gray-f2 rounded-[3px]"
          icon={faCircleCheck}
        />
      </Tooltip>
    </div>
  );
}
