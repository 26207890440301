import { faBan, faEye } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MessageStatus } from "../MsgModel";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../UI/Tooltip";

interface Props {
  onStopClick: () => void;
  onPreviewClick: () => void;
  messageStatus: MessageStatus;
  note?: string;
}

export default function Note(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <label className="flex-1 min-w-[20px]">{props.note}</label>
      <div className="flex justify-evenly">
        <Tooltip
          containerClassname="flex ml-1 p-[6px] hover:bg-gray-f2 rounded-[3px]"
          isHidden={props.messageStatus !== MessageStatus.SD}
          content={t("stop_scheduled_message")}
          position={{
            x: -70,
            y: 30,
          }}
        >
          <FontAwesomeIcon className="text-blue-primary" icon={faBan} onClick={props.onStopClick} />
        </Tooltip>

        <div className="flex mr-2 ml-7 p-[6px] hover:bg-gray-f2 rounded-[3px] cursor-pointer">
          <FontAwesomeIcon className="text-blue-primary" icon={faEye} onClick={props.onPreviewClick} />
        </div>
      </div>
    </div>
  );
}
