import React, { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

import Dropdown, { DropdownType } from "./Dropdown";
import i18next from "i18next";

const getAllGroupedPages = (pageCount: number) => {
  const groupedPagesLength = pageCount % 5 === 0 ? pageCount / 5 : Math.floor(pageCount / 5) + 1;

  const groupedPages = [];
  for (let i = 0; i < groupedPagesLength; i++) {
    const groupedPage = [];
    for (let j = 1; j <= 5; j++) {
      if (i * 5 + j <= pageCount) {
        groupedPage.push(i * 5 + j);
      }
    }
    groupedPages.push(groupedPage);
  }
  return groupedPages;
};

const previousPageAllowed = (allGroupedPages: number[][], pageIndex: number) => {
  let result = true;
  allGroupedPages.forEach((groupedPage) => {
    if (groupedPage[0] === pageIndex + 1) result = false;
  });
  return result;
};

const nextPageAllowed = (allGroupedPages: number[][], pageIndex: number) => {
  let result = true;
  allGroupedPages.forEach((groupedPage) => {
    if (groupedPage[groupedPage.length - 1] === pageIndex + 1) result = false;
  });
  return result;
};

const Pagination: React.FC<{
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (pageSize: number) => void;
  setPageNumber?: (pageSize: number) => void;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  pageLength: number;
  totalLength: number;
  dropdownKeys: string[];
  details?: {
    firstIndex: number;
    lastIndex: number;
    totalNum: number;
  };
}> = (props) => {
  const {
    previousPage,
    nextPage,
    setPageSize,
    setPageNumber,
    gotoPage,
    pageCount,
    pageIndex,
    pageSize,
    pageLength,
    totalLength,
    dropdownKeys,
    details,
  } = props;

  const { t } = useTranslation();

  const allGroupedPages = useMemo(() => getAllGroupedPages(pageCount), [pageCount]);

  if (allGroupedPages.length === 0) {
    return null;
  }

  const activeGroupedPagesIndex = allGroupedPages.findIndex(
    (groupedPages) => groupedPages.indexOf(pageIndex + 1) !== -1
  );

  const toEasternArabicNumerals = (num: string): string => {
    const westernToEastern: { [key: string]: string } = {
      "0": "٠",
      "1": "١",
      "2": "٢",
      "3": "٣",
      "4": "٤",
      "5": "٥",
      "6": "٦",
      "7": "٧",
      "8": "٨",
      "9": "٩",
    };
    return num
      .split("")
      .map((digit) => westernToEastern[digit as keyof typeof westernToEastern])
      .join("");
  };

  const options = dropdownKeys.map((key: string) => ({
    label: i18next.language === "ar" ? toEasternArabicNumerals(key) : key,
    value: key,
  }));

  const handlePageChange = (newPageIndex: number) => {
    if (setPageNumber) {
      setPageNumber(newPageIndex);
    }
    gotoPage(newPageIndex);
  };

  const handleOptionSelected = (option: string) => {
    const selectedOption = options.find((opt) => opt.label === option);
    const value = selectedOption ? selectedOption.value : "10";
    setPageSize(+value);
    handlePageChange(0);
  };

  return (
    <div className="flex justify-between flex-wrap gap-x-20 gap-y-6 mt-6" dir="ltr">
      <div className="my-auto">
        <button
          className="pagination-button bg-gray-e8 mx-1"
          onClick={() => {
            previousPage();
            if (setPageNumber) {
              setPageNumber(pageIndex - 1);
            }
          }}
          disabled={!previousPageAllowed(allGroupedPages, pageIndex)}
        >
          <div className="grid place-items-center">
            <FontAwesomeIcon className="text-xs" icon={faChevronLeft} />
          </div>
        </button>
        {activeGroupedPagesIndex !== 0 && (
          <button
            className="pagination-button"
            onClick={() => handlePageChange(allGroupedPages[activeGroupedPagesIndex - 1][0] - 1)}
          >
            ...
          </button>
        )}
        {allGroupedPages[allGroupedPages[activeGroupedPagesIndex] ? activeGroupedPagesIndex : 0].map((page) => (
          <button
            className={`pagination-button mx-1 text-sm ${page === pageIndex + 1 && "bg-blue-primary text-white"}`}
            key={page}
            onClick={() => handlePageChange(page - 1)}
          >
            <div className="grid place-items-center">
              {i18next.language === "ar" ? Intl.NumberFormat("ar-EG").format(page) : page}
            </div>
          </button>
        ))}
        {activeGroupedPagesIndex !== allGroupedPages.length - 1 && (
          <button
            className="pagination-button mx-1"
            onClick={() => handlePageChange(allGroupedPages[activeGroupedPagesIndex + 1][0] - 1)}
          >
            ...
          </button>
        )}
        <button
          className="pagination-button bg-gray-e8 mx-1"
          onClick={() => {
            nextPage();
            if (setPageNumber) {
              setPageNumber(pageIndex + 1);
            }
          }}
          disabled={!nextPageAllowed(allGroupedPages, pageIndex)}
        >
          <div className="grid place-items-center">
            <FontAwesomeIcon className="text-xs" icon={faChevronRight} />
          </div>
        </button>
      </div>
      <div className="flex items-center justify-end">
        <Dropdown
          type={DropdownType.SECONDARY}
          onOptionSelected={handleOptionSelected}
          keys={options.map((opt) => opt.label)}
          selectedKey={options.find((opt) => opt.value === pageSize.toString())?.label || options[0].label}
        />
        <p className="text-[15px] text-black27 ml-5 first-letter:uppercase">
          {t("showing_records", {
            firstIndex: details?.firstIndex
              ? i18next.language === "ar"
                ? Intl.NumberFormat("ar-EG").format(details.firstIndex)
                : details.firstIndex
              : i18next.language === "ar"
              ? Intl.NumberFormat("ar-EG").format(pageIndex * pageSize + 1)
              : pageIndex * pageSize + 1,

            lastIndex: details?.lastIndex
              ? i18next.language === "ar"
                ? Intl.NumberFormat("ar-EG").format(details.lastIndex)
                : details.lastIndex
              : i18next.language === "ar"
              ? Intl.NumberFormat("ar-EG").format(pageIndex * pageSize + pageLength)
              : pageIndex * pageSize + pageLength,

            totalNum: details?.totalNum
              ? i18next.language === "ar"
                ? Intl.NumberFormat("ar-EG").format(details.totalNum)
                : details.totalNum
              : i18next.language === "ar"
              ? Intl.NumberFormat("ar-EG").format(totalLength)
              : totalLength,
          })}
        </p>
      </div>
    </div>
  );
};

export default Pagination;
