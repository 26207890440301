import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import MsgStatusComponent from "../main/messaging/MsgStatusComponent";
import { ReceiverDeliveryStatus } from "../main/messaging/MsgModel";

const MessageSection: React.FC<{
  message: string;
  date: string;
  status: string;
  isExpanded?: boolean;
}> = ({ message, date, status, isExpanded }) => {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(isExpanded);

  useEffect(() => {
    setShowInfo(isExpanded);
  }, [isExpanded]);

  return (
    <section className="bg-white rounded mt-6 text-sm">
      <div
        className={`px-6 py-3.5 flex justify-between items-center rounded-t bg-gray-f6 ${!showInfo && "rounded-b"}`}
        onClick={() => setShowInfo((prev) => !prev)}
      >
        <div className="flex flex-row gap-x-6">
          <div
            className={showInfo ? "text-[#282A2E]" : "font-semibold text-[15px] text-[#007BB0]"}
          >
            {t("message")}
          </div>
          <div className={showInfo ? "text-gray-41" : "text-blue-71"}>{date}</div>
        </div>
        <FontAwesomeIcon
          icon={faAngleUp}
          className={`text-gray-8d cursor-pointer duration-300 ${showInfo ? "rotate-0" : "-rotate-180"
            }`}
        />
      </div>
      <div
        className={`overflow-hidden transition-max-height duration-300 ${showInfo ? "max-h-screen" : "max-h-0"}`}
      >
        <div className="p-6">
          <p className="font-proxima font-semibold text-black41 mb-6">{message}</p>
          <MsgStatusComponent msgStatus={ReceiverDeliveryStatus[status as keyof typeof ReceiverDeliveryStatus]} />
        </div>
      </div>
    </section>
  );
};

export default MessageSection;
