import React, { useState } from "react";
import { createPortal } from "react-dom";

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  position: { x: number; y: number };
  key?: string;
  containerClassname?: string;
  tooltipClassname?: string;
  isHidden?: boolean;
  onClick?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  position,
  key,
  containerClassname,
  tooltipClassname,
  content,
  isHidden,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: position.y + 10, left: position.x + 10 });

  const showTooltip = (event: React.MouseEvent<HTMLElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();

    setTooltipPosition({
      top: rect.top + window.scrollY + position.y,
      left: rect.left + window.scrollX + rect.width / 2 + position.x + 10,
    });

    setIsHovered(true);
  };

  const hideTooltip = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        key={key}
        className={`${containerClassname} relative bottom-full cursor-pointer`}
        style={{ visibility: isHidden ? "hidden" : "visible" }}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={onClick}
      >
        {children}
      </div>
      {isHovered &&
        createPortal(
          <div
            className={`${
              tooltipClassname ?? ""
            } text-gray-41 absolute left-1/2 mb-2 text-sm z-50 whitespace-nowrap bg-white p-2 rounded-[4px] shadow-lg`}
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
            }}
          >
            {content}
          </div>,
          document.body
        )}
    </>
  );
};

export default Tooltip;
