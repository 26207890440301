import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MainFooter from "../../../UI/MainFooter";
import MainContainer from "../../../UI/MainContainer";
import InformationSection from "../../../UI/InformationSection";
import BreadCrumb from "../../../UI/BreadCrumb";
import MessageSection from "../../../UI/MessageSection";
import Pagination from "../../../UI/Pagination";
import ChildrenAPI from "../../../../store/api/childrenAPI";
import { useLocation } from "react-router-dom";
import SearchSection from "../../../UI/SearchSection";
import MessageHistoryFilterBar, { MessageHistoryFilterBarType } from "./MessageHistoryFilterBar";
import FilterTag from "../../../UI/FilterTag";
import { getFormattedDate } from "../../../../utils/DateUtils";
import { getDate } from "../../../../utils/getDate";
import NoResultMessage from "../../../UI/NoResultMessage";
import { CircularProgress } from "@mui/material";
import { extractRcvDeliveryStatuses } from "../../../../utils/extractMessageStatuses";

type ChildMessageType = {
  text_message: string;
  updated_at: string;
  status: string;
};

type ChildType = {
  slug: string;
  first_name: string;
  last_name: string;
  national_id: string;
  age: string;
  contact: string;
  phone: string;
  messages: ChildMessageType[];
};

const DEFAULT_FILTER_STATE: MessageHistoryFilterBarType = {
  statuses: [{ id: "", name: "", type: [] }],
  dateFrom: null,
  dateTo: null,
};

const MessageHistory = () => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const parentContainer = useRef<HTMLDivElement>(null);
  const informationContiner = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [child, setChild] = useState<ChildType | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [totalMessagesCount, setTotalMessagesCount] = useState(0);

  const { getChild, getChildMessageHistory } = ChildrenAPI();

  const [searchInput, setSearchInput] = useState("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [filterState, setFilterState] = useState<MessageHistoryFilterBarType>(DEFAULT_FILTER_STATE);

  useEffect(() => {
    fetchChild();
  }, [location.state.slug]);

  useEffect(() => {
    fetchChildMessageHistory();
  }, [location.state.slug, pageIndex, pageSize, searchInput, filterState]);

  useEffect(() => {
    setPageIndex(0);
  }, [location.state.slug, searchInput, filterState]);

  useEffect(() => {
    if (child && child.messages?.length > 0) {
      const totalPages = Math.ceil(totalMessagesCount / pageSize);
      setPageCount(totalPages);
    }
  }, [child?.messages, pageSize]);

  function constructQueryParams() {
    let queryParams = [];
    queryParams.push(`page=${pageIndex + 1}`);
    queryParams.push(`size=${pageSize}`);

    if (searchInput) {
      queryParams.push(`search=${searchInput}`);
    }
    if (filterState.statuses.length > 0) {
      queryParams.push(...extractRcvDeliveryStatuses(filterState).map((key) => `status=${key}`));
    }
    if (filterState.dateFrom !== null) {
      queryParams.push(`send_date_from=${getDate(filterState.dateFrom.toString(), false)}`);
    }
    if (filterState.dateTo !== null) {
      queryParams.push(`send_date_to=${getDate(filterState.dateTo.toString(), false)}`);
    }

    return `?${queryParams.join("&")}`;
  }

  const fetchChild = () => {
    const slug = location?.state?.slug;

    if (!slug) {
      console.error("Slug is not available in location.state");
      return;
    }

    getChild(slug, async (response: Response) => {
      try {
        const result = await response.json();
        if (response.ok) {
          setChild(result);
        }
      } catch (err) {
        console.error(err);
      }
    });
  };

  const fetchChildMessageHistory = () => {
    const slug = location?.state?.slug;

    if (!slug) {
      console.error("Slug is not available in location.state");
      return;
    }
    setLoading(true);
    getChildMessageHistory(slug, constructQueryParams(), async (response: Response) => {
      setLoading(false);
      try {
        const result = await response.json();
        if (response.ok) {
          setTotalMessagesCount(result.count);
          setChild((prevChild) => {
            if (!prevChild) return undefined;
            return {
              ...prevChild,
              messages: result.results,
            };
          });
        }
      } catch (err) {
        console.error(err);
      }
    });
  };

  let sendDateFilterTagValue =
    filterState.dateFrom && filterState.dateTo
      ? `${getFormattedDate(filterState.dateFrom)} - ${getFormattedDate(filterState.dateTo)}`
      : filterState.dateFrom
      ? `${t("from")} ${getFormattedDate(filterState.dateFrom)}`
      : filterState.dateTo
      ? `${t("to")} ${getFormattedDate(filterState.dateTo)}`
      : "";

  const gotoPage = (updater: number | ((pageIndex: number) => number)) => {
    if (typeof updater === "function") {
      setPageIndex(updater(pageIndex));
    } else {
      setPageIndex(updater);
    }
  };

  const resetFilter = (filterName: keyof MessageHistoryFilterBarType) => {
    setFilterState((prev) => ({
      ...prev,
      [filterName]: DEFAULT_FILTER_STATE[filterName],
    }));
  };

  return (
    <MainContainer>
      <main ref={parentContainer} className="alignment mb-8">
        {child && (
          <>
            <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-27">{t("message_history")}</h3>
                <BreadCrumb
                  className="mt-[2px] mx-2"
                  availableLinks={[{ title: t("children"), path: "/children" }, { title: t("message_history") }]}
                />
              </div>
            </header>
            <div className="flex flex-col">
              <section ref={informationContiner}>
                {child ? (
                  <InformationSection
                    className="mt-4"
                    title={t("child_personal_information")}
                    information={[
                      {
                        label: t("full_name"),
                        value: child.first_name + " " + child.last_name,
                      },
                      {
                        label: t("national_id"),
                        value: child.national_id ?? "-",
                      },
                      {
                        label: t("age"),
                        value: child.age ?? "-",
                      },
                      {
                        label: t("contact_name"),
                        value: child.contact ?? "-",
                      },
                      {
                        label: t("phone_number"),
                        value: child.phone ?? "-",
                      },
                    ]}
                  />
                ) : (
                  <div>{t("loading_child_information")}</div>
                )}

                <section className="bg-white rounded mt-6 text-sm">
                  <SearchSection
                    onSearch={(_searchVal) => setSearchInput(_searchVal)}
                    onSliderClicked={() => setShowFilterSection((preState) => !preState)}
                    isFilterMode={showFilterSection}
                  />

                  <div className={`${showFilterSection ? "h-auto block pt-2 pb-6" : "h-0 hidden"} duration-500`}>
                    <MessageHistoryFilterBar
                      applyHandler={(value) => {
                        setFilterState(value);
                      }}
                      resetHandler={() => {
                        setFilterState(DEFAULT_FILTER_STATE);
                      }}
                      statusValue={filterState.statuses}
                      dateValue={{
                        dateFrom: filterState.dateFrom,
                        dateTo: filterState.dateTo,
                      }}
                    />
                    <div
                      className={`w-95/100 mx-auto flex bg-white ${
                        showFilterSection && JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
                          ? "pt-4 block"
                          : "hidden"
                      } duration-500 overflow-hidden`}
                    >
                      <div className="text-black41 text-sm flex flex-wrap gap-2">
                        <label className="my-auto">{t("applied_filter")}:</label>
                        {filterState.statuses?.length > 0 &&
                          !filterState.statuses.some(
                            ({ id, name, type }) => id === "" && name === "" && type.length === 0
                          ) && (
                            <FilterTag
                              label={t("status")}
                              value={filterState.statuses.map((key) => key.name).join(", ")}
                              onClose={() => resetFilter("statuses")}
                            />
                          )}
                        {sendDateFilterTagValue && (
                          <FilterTag
                            label={t("send_time")}
                            value={sendDateFilterTagValue}
                            onClose={() => {
                              resetFilter("dateFrom");
                              resetFilter("dateTo");
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                {loading ? (
                  <div className="flex w-full h-[500px] items-center justify-center">
                    <CircularProgress size={64} style={{ color: "#11589a" }} />
                  </div>
                ) : child?.messages?.length ? (
                  <div className="relative">
                    {loading && (
                      <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-10">
                        <CircularProgress size={64} style={{ color: "#11589a" }} />
                      </div>
                    )}
                    <>
                      {child.messages.map((item, index) => (
                        <MessageSection
                          key={index}
                          message={item.text_message}
                          date={item.updated_at}
                          status={item.status}
                          isExpanded={index === 0}
                        />
                      ))}
                      <Pagination
                        dropdownKeys={["5", "10", "20"]}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        previousPage={() => {
                          setPageIndex((prev) => Math.max(0, prev - 1));
                        }}
                        nextPage={() => {
                          setPageIndex((prev) => Math.min(pageCount - 1, prev + 1));
                        }}
                        setPageSize={setPageSize}
                        gotoPage={gotoPage}
                        pageCount={pageCount}
                        pageLength={child.messages.length}
                        totalLength={totalMessagesCount}
                      />
                    </>
                  </div>
                ) : (
                  <div className="text-center py-2.5 rounded bg-white mt-6">
                    <NoResultMessage noResultOrNoData={true} />
                  </div>
                )}
              </section>
              <MainFooter />
            </div>
          </>
        )}
      </main>
    </MainContainer>
  );
};

export default MessageHistory;
