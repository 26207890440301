import React from "react";
import ReactDatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import i18next from "i18next";

interface TimePickerProps {
  showTimePicker: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  minTime?: Date;
  maxTime?: Date;
}

const TIMEPICKER_DEFAULT_FORMAT = "dd/MM/yyyy - HH:mm";
const DATEPICKER_DEFAULT_FORMAT = "dd/MM/yyyy";

const DatePicker: React.FC<{
  inputClassName?: string;
  containerClassName?: string;
  selected: Date | null;
  placeholderText: string;
  onChange: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
  timePickerConfig?: TimePickerProps;
  format?: string;
}> = ({
  inputClassName,
  containerClassName,
  selected,
  placeholderText,
  onChange,
  minDate,
  maxDate,
  timePickerConfig,
  format,
}) => {
  return (
    <div
      className={`flex w-full rounded border border-gray-df bg-gray-f6 hover:bg-white relative ${containerClassName}`}
      style={{ position: timePickerConfig ? "inherit" : "relative" }}
    >
      <FontAwesomeIcon
        icon={faCalendarAlt}
        className={`text-sm text-gray-d7 absolute ${i18next.language === "ar" ? "left-3" : "right-3"}  top-4 z-10`}
      />
      <ReactDatePicker
        className={`search-filter__input min-w-[120px] border-0 outline-none ${inputClassName ?? ""}`}
        selected={selected}
        placeholderText={placeholderText}
        onChange={(date) => onChange(date)}
        maxDate={maxDate}
        minDate={minDate}
        dateFormat={format ?? (timePickerConfig ? TIMEPICKER_DEFAULT_FORMAT : DATEPICKER_DEFAULT_FORMAT)}
        showTimeSelect={timePickerConfig?.showTimePicker}
        timeIntervals={timePickerConfig?.timeIntervals}
        timeFormat={timePickerConfig?.timeFormat}
        minTime={timePickerConfig?.minTime}
        maxTime={timePickerConfig?.maxTime}
      />
      {/* <FontAwesomeIcon
        icon={faCalendarAlt}
        style={{ position: timePickerConfig ? "relative" : "absolute" }}
        className={`text-md text-gray-d7 top-3 z-10 right-2`}
      /> */}
    </div>
  );
};

export default DatePicker;
