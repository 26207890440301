import React from "react";

interface TooltipProps {
  children: React.ReactNode;
  show: boolean;
  position: { x: number; y: number };
  style?: React.CSSProperties;
}

const ClickableTooltip: React.FC<TooltipProps> = ({ children, show, position, style }) => {
  if (!show) return null;

  const _style = {
    ...style,
    top: position.y + 10,
    left: position.x + 10,
  };

  return (
    <div className="relative">
      <div
        style={_style}
        className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 z-50 whitespace-nowrap"
      >
        <div className="flex bg-white p-2 rounded-[4px] shadow-lg">{children}</div>
      </div>
    </div>
  );
};

export default ClickableTooltip;
