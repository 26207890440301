import { useState } from "react";
import ClickableTooltip from "../../../UI/ClickableTooltip";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../UI/Tooltip";

interface Props {
  sendTime: string;
  isStopped: boolean;
}

export default function SendTimeCell(props: Props) {
  const { t } = useTranslation();

  return props.isStopped ? (
    <Tooltip
      containerClassname="w-fit cursor-pointer hover:bg-gray-f2 rounded-[3px]"
      content={t("message_is_stopped_description")}
      position={{
        x: -50,
        y: 25,
      }}
    >
      <label className={props.isStopped ? "text-gray-b9 line-through" : ""}>{props.sendTime.translateDigits()}</label>
    </Tooltip>
  ) : (
    <label className={props.isStopped ? "text-gray-b9 line-through" : ""}>{props.sendTime.translateDigits()}</label>
  );
}
