import { CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const PrimaryButton: React.FC<{
  primaryOrNot: boolean;
  title?: string;
  children?: React.ReactNode;
  onClick: () => void;
  className?: string;
  onBlur?: () => void;
  loading?: boolean;
}> = (props) => {
  const { primaryOrNot, title, children, onClick, className, onBlur, loading } = props;
  const { t } = useTranslation();
  return (
    <button
      onClick={() => {
        if (!loading) onClick();
      }}
      onBlur={onBlur}
      className={`relative ${className} ${
        primaryOrNot
          ? " bg-blue-primary text-white rounded-md w-auto h-auto px-6 py-2 border border-solid hover:bg-[#1C68AF]"
          : `px-7 py-2 text-blue-primary hover:bg-blue-primary hover:text-white rounded-md w-auto bg-[#8888882D] h-auto ${
              i18next.language === "ar" ? "ml-4" : "mr-4"
            } ease-out transition-all duration-500`
      }`}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <CircularProgress size={18} style={{ color: "white" }} />
        </div>
      ) : (
        <div>
          <span>{children}</span>
          {title && <span className="mx-2 text-sm ab">{t(title)} </span>}
        </div>
      )}
    </button>
  );
};

export default PrimaryButton;
