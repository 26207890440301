import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const FilterTag: React.FC<{
  label: string;
  value: string;
  onClose: () => void;
  className?: string;
}> = ({ label, value, onClose, className }) => {
  return (
    <div
      className={`${className} flex items-center py-[10px] px-3 h-10 rounded-[3px] text-blue-primary bg-blue-e2 text-sm`}
    >
      <label>{label}</label>
      <span>:</span>
      <p className="mx-1 whitespace-pre-wrap font-bold">{value}</p>
      <FontAwesomeIcon onClick={() => onClose()} className="text-base mt-[2px] mx-1 cursor-pointer" icon={faTimes} />
    </div>
  );
};

export default FilterTag;
