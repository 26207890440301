import { MessageStatus, MessageStatusDetail, RepeatFrequency } from "../MsgModel";
import MsgStatusComponent, { getStatusBoxStyle } from "../MsgStatusComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Tooltip from "../../../UI/Tooltip";

interface Props {
  receiversInfoStats: Array<MessageStatusDetail>;
  hasRepeat: boolean;
  repeatFrequncyType?: RepeatFrequency;
}

export default function MessageStatusCell(props: Props) {
  const { t } = useTranslation();

  // Sort and show the highest count as first element
  props.receiversInfoStats.sort((itemOne, itemTwo) => itemTwo.count - itemOne.count);

  return (
    <ul className="flex items-center">
      <div className="flex items-center justify-center">
        <MsgStatusComponent
          msgStatus={props.receiversInfoStats.length > 0 ? props.receiversInfoStats[0].status : MessageStatus.NN}
          key={props.receiversInfoStats.length.translateDigits()}
        />

        {props.receiversInfoStats.length > 0 && (
          <MessageStatusContainer msgStatusComponentArr={props.receiversInfoStats} />
        )}
      </div>

      {props.hasRepeat && (
        <Tooltip
          containerClassname="mx-1"
          content={`${t(props.repeatFrequncyType!.toLowerCase())} ${t("repeat")}`}
          position={{
            x: 10,
            y: 10,
          }}
        >
          <FontAwesomeIcon className="text-gray-8d hover:text-blue-primary" icon={faRepeat} />
        </Tooltip>
      )}
    </ul>
  );
}

interface MessageStatusContainerProps {
  msgStatusComponentArr: Array<MessageStatusDetail>;
}

function MessageStatusContainer(props: MessageStatusContainerProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      containerClassname="flex w-8 h-8 items-center justify-center p-2 text-blue-primary bg-gray-e8 rounded-[3px] hover:text-white hover:bg-blue-secondary cursor-pointer"
      content={
        <div className="flex items-center justify-center text-blue-primary rounded-[3px] hover:text-white hover:bg-blue-secondary cursor-pointer">
          <div className="flex flex-col">
            {props.msgStatusComponentArr.map((msgSta, index) => (
              <div className="flex mt-2" key={index}>
                <label className={`mx-1 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>
                  {msgSta.count.translateDigits()}
                </label>
                <label className={`mx-1 ${getStatusBoxStyle(msgSta.status)} bg-transparent`}>{t(msgSta.status)}</label>
              </div>
            ))}
          </div>
        </div>
      }
      position={{
        x: 30,
        y: 10,
      }}
    >
      <label className="cursor-pointer">{props.msgStatusComponentArr.length.translateDigits()}</label>
    </Tooltip>
  );
}
