import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../UI/Modal";
import { faClose } from "@fortawesome/pro-light-svg-icons";
import SearchSection from "../../../UI/SearchSection";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../../UI/PrimaryButton";
import Dropdown, { DropdownType } from "../../../UI/Dropdown";
import ContactsTable from "./ContactsTable";
import { ContactListFilterConfig, ContactListSortConfig, ContactObj } from "./ContactModel";
import MsgManagementAPI from "../../../../store/api/MsgManagementAPI";
import { CircularProgress } from "@mui/material";
import NumberInput from "../../../UI/NumberInput";
import { RecipientObj } from "../MsgModel";
import NoResultMessage from "../../../UI/NoResultMessage";

interface Props {
  onSelectContacts: (selectedContacts: Array<RecipientObj>) => void;
  receipentArray: Array<RecipientObj>;
  onClose: () => void;
}

const TemplateContacts = [
  {
    id: "1",
    fullName: "Parsa",
    childDays: 3,
    childName: "Ali",
    clinics: ["Test Clinic", "Test 2 Clinic"],
    gender: "Male",
    phone: "+989109392492",
    role: 1,
    roleString: "Staff",
  },
  {
    id: "2",
    fullName: "Parsa 2",
    childDays: 22,
    childName: "Gholi",
    clinics: ["Test Clinic", "Test 5 Clinic", "Test 5 Clinic", "Test 5 Clinic"],
    gender: "Male",
    phone: "+989109392493",
    role: 3,
    roleString: "Caregiver",
  },
  {
    id: "3",
    fullName: "Parsa 3",
    childDays: 25,
    childName: "Taghi",
    clinics: ["Test 3 Clinic"],
    gender: "Male",
    phone: "+989109392494",
    role: 4,
    roleString: "Clinic Admin",
    isSelected: true,
  },
  {
    id: "4",
    fullName: "Parsa Dande Be Dande",
    childDays: 35,
    childName: "Naghi",
    clinics: ["Test 6 Clinic"],
    gender: "Male",
    phone: "+989109392495",
    role: 4,
    roleString: "Clinic Admin",
  },
  {
    id: "5",
    fullName: "Parsa Chera Nemikhande?",
    childDays: 49,
    childName: "Booghi",
    clinics: ["N Clinic"],
    gender: "Male",
    phone: "+989109392495",
    role: 3,
    roleString: "Caregiver",
  },
];

export type KeyValueObj = {
  key: string;
  value: string;
};

export type ContactListPaginationConfig = {
  pageSize: number;
  pageIndex: number;
};

const DEFAULT_FILTER_CONFIG: ContactListFilterConfig = {
  showFilterSection: false,
};

const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 10;

export default function ContactListModal(props: Props) {
  const { t } = useTranslation();
  const { getContacts, getClinics } = MsgManagementAPI();

  const [filterConfig, setFilterConfig] = useState<ContactListFilterConfig | undefined>(undefined);
  const [tempFilterConfig, setTempFilterConfig] = useState<ContactListFilterConfig>(DEFAULT_FILTER_CONFIG);
  const [sortConfig, setSortConfig] = useState<ContactListSortConfig | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState<ContactListPaginationConfig>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>(undefined);
  const [contactList, setContactList] = useState<Array<ContactObj>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [clinics, setClinics] = useState<Array<KeyValueObj>>([]);
  const [selectedContacts, setSelectedContacts] = useState<Array<RecipientObj>>(props.receipentArray);
  const [childAgeErr, setChildAgeErr] = useState<string | undefined>(undefined);

  const contactRoles: Array<KeyValueObj> = [
    {
      key: "-1",
      value: "all",
    },
    {
      key: "1",
      value: "Staff",
    },
    {
      key: "3",
      value: "Caregiver",
    },
    {
      key: "4",
      value: "Clinic Admin",
    },
  ];

  useEffect(() => {
    getClinics(async (response: Response) => {
      const result = await response.json();

      setClinics(
        result.map((res: any) => ({
          key: res.slug,
          value: res.name,
        }))
      );
    });
  }, []);

  useEffect(() => {
    setLoading(true);

    getContacts(
      async (response: Response) => {
        const result = await response.json();
        setTotalDataCount(result.count);

        const contacts: Array<ContactObj> = [];
        result.results?.forEach((contact: any) => {
          const newContact: ContactObj = {
            id: contact.id,
            fullName: contact.full_name,
            phone: contact.phone,
            childDays: contact.child_days,
            childName: contact.child_name,
            clinics: contact.clinics.map((cli: any) => cli.name),
            gender: contact.gender,
            role: contact.role,
            roleString: contact.role_string,
            isSelected: selectedContacts.find((sc) => sc.id === contact.id) !== undefined,
          };

          contacts.push(newContact);
        });
        setContactList(contacts);
        setLoading(false);
      },
      paginationConfig.pageIndex + 1,
      paginationConfig.pageSize,
      searchPhrase,
      sortConfig,
      filterConfig
    );
  }, [paginationConfig.pageIndex, paginationConfig.pageSize, searchPhrase, sortConfig, filterConfig]);

  return (
    <Modal width={1140}>
      <div className="flex flex-col py-6">
        <div className="flex items-center justify-between px-6">
          <label className="text-black41 font-semibold">{t("contacts")}</label>
          <FontAwesomeIcon icon={faClose} className="cursor-pointer text-lg" onClick={props.onClose} />
        </div>

        <section className="flex mt-4 flex-col items-center justify-center">
          <SearchSection
            onSearch={(search) => {
              setSearchPhrase(search);
              setPaginationConfig((preValue) => ({ ...preValue, pageIndex: DEFAULT_PAGE_INDEX }));
            }}
            isFilterMode={tempFilterConfig.showFilterSection}
            onSliderClicked={() =>
              setTempFilterConfig({ ...filterConfig, showFilterSection: !tempFilterConfig.showFilterSection })
            }
          />
          <hr className="h-[6px] bg-gray-f5 border-none" />

          {tempFilterConfig.showFilterSection && (
            <div className="flex w-full flex-col sm:flex-row px-6 flex-wrap">
              <div className="flex flex-1 pb-6 flex-wrap">
                <div className="mr-6">
                  <label className="text-sm text-black41">{t("type")}</label>
                  <div className="mt-1">
                    <Dropdown
                      keys={contactRoles.map((cr) => cr.value)}
                      type={DropdownType.PRIMARY}
                      onOptionSelected={(key: string) => {
                        setTempFilterConfig((preValue) => ({
                          ...preValue,
                          role: Number(contactRoles.find((cr) => cr.value === key)?.key),
                        }));
                      }}
                      selectedKey={
                        contactRoles.find((cr) => cr.key === tempFilterConfig.role?.toString())?.value ?? t("all")
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-col mr-3 justify-end mt-1">
                  <label className="text-sm text-black41">{`${t("child_age")} (${t("week")})`}</label>
                  <div className="flex">
                    <div className="mt-1 w-32">
                      <NumberInput
                        className="placeholder:text-gray-d7 w-[120px]"
                        value={tempFilterConfig.childAgeFrom ?? ""}
                        placeholder={t("from")}
                        onNumberInputChanged={(value) => {
                          setTempFilterConfig((preValue) => ({
                            ...preValue,
                            childAgeFrom: value,
                            childAgeFromInDays: Number(value) * 7,
                          }));
                        }}
                      />
                    </div>

                    <div className="flex w-32 items-end mr-6">
                      <NumberInput
                        className="placeholder:text-gray-d7 w-[120px]"
                        value={tempFilterConfig.childAgeTo ?? ""}
                        placeholder={t("to")}
                        onNumberInputChanged={(value) => {
                          setTempFilterConfig((preValue) => ({
                            ...preValue,
                            childAgeTo: value,
                            childAgeToInDays: Number(value) * 7,
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {childAgeErr && <label className="text-sm pt-1 text-red-primary">{childAgeErr}</label>}
                </div>

                <div className="">
                  <label className="text-sm text-black41">{t("clinic")}</label>
                  <div className="mt-1">
                    <Dropdown
                      keys={clinics.map((cli) => cli.value)}
                      type={DropdownType.PRIMARY}
                      onOptionSelected={(key: string) => {
                        setTempFilterConfig((preValue) => ({
                          ...preValue,
                          clinicSlug: clinics.find((cli) => cli.value === key)?.key,
                        }));
                      }}
                      selectedKey={clinics.find((cli) => cli.key === tempFilterConfig.clinicSlug)?.value ?? t("all")}
                      getValueByKey={(key) => {
                        return clinics.find((clinic) => clinic.value === key)?.value ?? t("all");
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center sm:justify-end">
                <label
                  className="link mx-6"
                  onClick={() => {
                    setTempFilterConfig({ ...DEFAULT_FILTER_CONFIG, showFilterSection: true });
                    setChildAgeErr(undefined);
                    setFilterConfig(undefined);
                  }}
                >
                  {t("reset")}
                </label>
                <PrimaryButton
                  onClick={() => {
                    if (
                      tempFilterConfig.childAgeFrom &&
                      tempFilterConfig.childAgeTo &&
                      tempFilterConfig.childAgeFrom > tempFilterConfig.childAgeTo
                    ) {
                      setChildAgeErr(t("invalid_range_error_desc"));
                    } else {
                      setChildAgeErr(undefined);
                      setFilterConfig(tempFilterConfig);
                    }
                  }}
                  primaryOrNot
                  title={t("apply")}
                />
              </div>
            </div>
          )}

          <div className="flex flex-col w-full border-t-[1px]">
            {loading ? (
              <div className="flex justify-center items-center">
                <CircularProgress className="my-40" />
              </div>
            ) : (
              <div className="flex flex-col w-full">
                {contactList.length === 0 && !loading ? (
                  <NoResultMessage noResultOrNoData={true} />
                ) : (
                  <ContactsTable
                    data={contactList}
                    onDataUpdated={(updatedData) => {
                      setContactList(updatedData);

                      // Check the previous selected contacts statuses changed or not.
                      // If changed so we should update our array.
                      let previousSelectedContacts = [...selectedContacts];
                      previousSelectedContacts.forEach((psc) => {
                        const newContactStatus = updatedData.find((cont) => cont.id === psc.id);

                        if (newContactStatus && !newContactStatus.isSelected) {
                          previousSelectedContacts = previousSelectedContacts.filter(
                            (_psc) => _psc.id !== newContactStatus?.id
                          );
                        }
                      });

                      // Save the new selected items and previous selected items.
                      const mergedContacts = [
                        ...previousSelectedContacts,
                        ...updatedData
                          .filter((con) => Boolean(con.isSelected))
                          .map((con) => ({ id: con.id, name: con.fullName, phone: con.phone })),
                      ];

                      // Remove duplicate elements in the case our contact has two or more children.
                      const map = new Map();
                      mergedContacts.forEach((user) => {
                        if (!map.has(user.id)) {
                          map.set(user.id, user);
                        }
                      });

                      setSelectedContacts(Array.from(map.values()));
                    }}
                    totalDataCount={totalDataCount}
                    paginationConfig={paginationConfig}
                    setPaginationConfig={setPaginationConfig}
                    setSortConfig={setSortConfig}
                    sortConfig={sortConfig}
                  />
                )}

                <div className="flex items-center justify-end mt-8 mx-6">
                  <label className="link text-gray-41 mx-6" onClick={props.onClose}>
                    {t("cancel")}
                  </label>
                  <PrimaryButton
                    onClick={() => {
                      props.onSelectContacts(selectedContacts);
                      props.onClose();
                    }}
                    primaryOrNot
                    title={t("select")}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </Modal>
  );
}
