import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import MainContainer from "../../UI/MainContainer";
import ChildrenType from "../../../types/Children";
import MainFooter from "../../UI/MainFooter";
import ChildrenAPI from "../../../store/api/childrenAPI";
import NoResultMessage from "../../UI/NoResultMessage";
import ChildrenTable from "./ChildrenTable";
import SelectedChildren from "./SelectedChildren";
import ResendMessageModal from "./ResendMessageModal";
import { SelectedChildrenContext } from "../../../store/SelectedChildrenContext";
import { CircularProgress, Snackbar, SnackbarContent } from "@mui/material";
import { SnackBarType } from "../../../utils/Constants";
import SearchSection from "../../UI/SearchSection";
import ChildrenFilterSection from "./ChildrenFilterSection";
import { SearchFilterChildrenContext, VaccineStatus } from "../../../store/SearchFilterChildrenContext";
import PrimaryButton from "../../UI/PrimaryButton";
import saveAs from "file-saver";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { Column, Row } from "react-table";
import i18next from "i18next";
import IdValueType from "../../../types/IdValueType";
import { getDate } from "../../../utils/getDate";

export type ChildVaccine = {
  id: string;
  name: string;
  status: string;
  date: string;
  clinic: string;
};

type PaginationConfig = {
  pageIndex: number;
  pageSize: number;
};

export type SortConfig = {
  column: string;
  sortType: "Ascending" | "Descending";
  vaccineName?: string;
  vaccineId?: string;
};

const Children = () => {
  const { t } = useTranslation();
  const isInitialRender = useRef(true);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [displayableChildren, setDisplayableChildren] = useState<ChildrenType[]>([]);
  const children = useMemo(() => displayableChildren, [displayableChildren]);
  const [vaccinesList, setVaccinesList] = useState<IdValueType[]>([]);
  const [vaccinesTypeAndStatus, setVaccinesTypeAndStatus] = useState<VaccineStatus[]>([]);
  const [showResendMessageModal, setShowResendMessageModal] = useState(false);
  const [snackBarType, setSnackBarType] = useState<SnackBarType>();
  const { getChildren, sendMessage, exportChildren } = ChildrenAPI();
  const [loading, setLoading] = useState(false);
  const [showChildrenLoading, setShowChildrenLoading] = useState(false);
  const [onApplyClicked, setOnApplyClicked] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    show: boolean;
    message: string;
  }>({
    show: false,
    message: "",
  });
  const { selectedChildren, clearSelectedChildren } = useContext(SelectedChildrenContext);
  const { searchValue, setSearchValue, childrenFilter, childrenAppliedFilter, setChildrenAppliedFilter, setLanguage } =
    useContext(SearchFilterChildrenContext);
  const [exporting, setExporting] = useState(false);
  const [paginationConf, setPaginationConf] = useState<PaginationConfig>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [pageCount, setPageCount] = useState(0);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(undefined);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [currLanguage, setCurrLanguage] = useState<string | null>(null);

  useEffect(() => {
    setCurrLanguage(localStorage.getItem("auth_data") ? JSON.parse(localStorage.getItem("auth_data")!).lang : "en");
  }, []);

  useEffect(() => {
    setLanguage(i18next.language);
    if (currLanguage && currLanguage !== i18next.language) {
      setShowChildrenLoading(true);
      fetchChildren();
      setCurrLanguage(i18next.language);
    }
  }, [i18next.language]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    setShowChildrenLoading(true);
    fetchChildren();
    setOnApplyClicked(false);
  }, [onApplyClicked, childrenAppliedFilter, paginationConf.pageIndex, paginationConf.pageSize, sortConfig]);

  useEffect(() => {
    if (isInitialRender.current) return;
    if (!showFilterSection) {
      setShowChildrenLoading(true);
      fetchChildren();
    }
  }, [searchValue]);

  useEffect(() => {
    setPaginationConf((prevConf) => ({
      ...prevConf,
      pageIndex: 0,
    }));
  }, [searchValue, childrenAppliedFilter]);

  function constructQueryParams() {
    let queryParams = [];
    let shouldSort = true;
    queryParams.push(`country=${process.env.REACT_APP_Country}`);
    queryParams.push(`page_number=${paginationConf.pageIndex}`);
    queryParams.push(`page_size=${paginationConf.pageSize}`);

    if (searchValue) {
      queryParams.push(`search=${encodeURIComponent(searchValue)}`);
    }

    if (childrenAppliedFilter.vaccines.length > 0) {
      let vaccineIsInFilters = childrenAppliedFilter.vaccines.find((vaccine) => vaccine.id === sortConfig?.vaccineId);
      shouldSort = vaccineIsInFilters !== undefined;

      let vaccineIds = childrenAppliedFilter.vaccines.map((vaccine) => vaccine.id).join(",");
      if (vaccineIds !== "") {
        queryParams.push(`vaccination_list=${vaccineIds}`);
      }
    }

    if (childrenAppliedFilter.injectionStatuses.length > 0) {
      let statusKeys = childrenAppliedFilter.injectionStatuses.map((status) => status.key).join(",");
      if (statusKeys !== "") {
        queryParams.push(`child_vaccination_status=${statusKeys}`);
      }
    }

    if (childrenAppliedFilter.vaccineStatuses.length > 0) {
      let typeKeys = childrenAppliedFilter.vaccineStatuses.map((type) => type.key).join(",");
      if (typeKeys !== "") {
        queryParams.push(`vaccination_status=${typeKeys}`);
      }
    }

    if (childrenAppliedFilter.clinic !== "") {
      queryParams.push(`clinic_slug=${childrenAppliedFilter.clinic}`);
    }
    if (childrenAppliedFilter.dateFrom !== null) {
      queryParams.push(`birthdate_from=${getDate(childrenAppliedFilter.dateFrom.toString(), false)}`);
    }
    if (childrenAppliedFilter.dateTo !== null) {
      queryParams.push(`birthdate_to=${getDate(childrenAppliedFilter.dateTo.toString(), false)}`);
    }

    if (shouldSort && sortConfig) {
      if (sortConfig.vaccineName) {
        queryParams.push(`vaccine_name=${sortConfig.vaccineName}`);
      } else {
        queryParams.push(`name=${sortConfig.column}`);
      }
      queryParams.push(`sort_type=${sortConfig.sortType}`);
    }

    return `?${queryParams.join("&")}`;
  }

  const fetchChildren = () => {
    getChildren(async (response: Response) => {
      const result = await response.json();
      setShowChildrenLoading(false);
      if (response.ok) {
        setPageCount(result.page_count);
        setTotalDataCount(result.total_count);
        setDisplayableChildren(result.children);
        setVaccinesList(result.vaccination);
        setVaccinesTypeAndStatus(() => {
          return [...result.child_vaccination_status, ...result.vaccination_type];
        });
      }
    }, `children-list-revised/${constructQueryParams()}`);
  };

  const onApply = () => {
    if (childrenFilter.vaccineStatuses.length > 0) {
      setSortConfig(undefined);
    }

    setChildrenAppliedFilter({ ...childrenFilter });
    setOnApplyClicked(true);
  };

  const generateDynamicColumns = () =>
    vaccinesList.map((vaccine) => ({
      header: vaccine.name,
      accessor: vaccine.name,
      sortType: (rowA: Row<ChildrenType>, rowB: Row<ChildrenType>, columnId: string) => {
        const parseDate = (dateStr: string) => {
          if (!dateStr) return null;
          const parts = dateStr.split("/");
          return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
        };
        let a = parseDate((rowA.values[columnId] as ChildVaccine).date);
        let b = parseDate((rowB.values[columnId] as ChildVaccine).date);
        if (a === null && b !== null) return 1;
        if (b === null && a !== null) return -1;
        if (a === null && b === null) return 0;
        return a!.getTime() - b!.getTime();
      },
    }));

  const _columns: Column<ChildrenType>[] = useMemo(
    () => [
      {
        header: t("name"),
        accessor: "full_name",
      },
      {
        header: t("phone_number"),
        accessor: "phone_number",
      },
      {
        header: t("national_id"),
        accessor: "national_id",
      },
      {
        header: t("registered_clinic"),
        accessor: "clinic",
      },
      {
        header: t("area"),
        accessor: "area",
      },
    ],
    [t]
  );

  const sendMessageHandler = (message: string) => {
    setLoading(true);
    sendMessage(
      selectedChildren.map((selectedChild) => ({ slug: selectedChild })),
      message,
      async (response) => {
        const result = await response.json();
        if (response.ok) {
          setSnackbar({ message: t("message_sent_successfully"), show: true });
          setLoading(false);
          setShowResendMessageModal(false);
          clearSelectedChildren();
          setSnackBarType(SnackBarType.IMPORT);
        } else {
          setLoading(false);
          setSnackBarType(SnackBarType.IMPORTFAILED);
          setSnackbar({ message: result, show: true });
        }
      }
    );
  };

  const exportChildrenHandler = () => {
    setExporting(true);

    exportChildren(constructQueryParams(), async (response) => {
      if (response.ok) {
        setTimeout(async () => {
          try {
            const blob = await response.blob();
            saveAs(blob, ` "children".csv`);
            setExporting(false);
            setSnackbar({
              show: true,
              message: t("data_exported_successfully"),
            });
          } catch (e) {
            console.log("error:file not found");
          } finally {
            setExporting(false);
          }
        }, 1000);
      }
    });
  };

  return (
    <MainContainer>
      <Snackbar
        open={snackbar.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: `${snackBarType === SnackBarType.IMPORTFAILED ? "#D11C1C" : "#03A678"}`,
          },
          "& .MuiSnackbarContent-message": {
            fontSize: "16px",
            fontFamily: "'Open Sans', sans-serif",
            fontWeight: "100",
          },
        }}
        onClose={() => {
          setSnackbar({
            show: false,
            message: "",
          });
        }}
      >
        <SnackbarContent message={<p>{snackbar.message}</p>} />
      </Snackbar>
      {exporting && <LoadingSpinner onBackdrop={() => setExporting(false)} titleKey={t("exporting_to_csv")} />}

      <main className="alignment mb-8">
        <header className="flex flex-col mt-4 md:flex-row md:justify-between md:items-center md:mt-0">
          <h3 className="text-lg font-semibold text-gray-27">{t("children")}</h3>
          <div className="mt-2 md:mt-0">
            {!showChildrenLoading && displayableChildren.length > 0 && (
              <PrimaryButton onClick={exportChildrenHandler} primaryOrNot title={t("export_to_csv")} />
            )}
          </div>
        </header>

        <section className="w-full rounded mt-6 bg-white pb-6">
          <SearchSection
            onSearch={(input) => setSearchValue(input)}
            onSliderClicked={() => setShowFilterSection((preShowFilterSection) => !preShowFilterSection)}
            isFilterMode={showFilterSection}
          />
          {showFilterSection && <ChildrenFilterSection onApply={onApply} />}
          <hr className="h-[6px] bg-gray-f5 border-none" />
          <SelectedChildren setShowResendMessageModal={setShowResendMessageModal} />
          <main className="w-95/100 mx-auto relative">
            {showChildrenLoading && <LoadingModal isOpen={showChildrenLoading} />}

            {displayableChildren.length > 0 ? (
              <ChildrenTable
                data={children}
                // columns={[..._columns, ...generateDynamicColumns()]}
                staticColumns={_columns}
                dynamicColumns={generateDynamicColumns()}
                vaccines={vaccinesList}
                vaccinesTypeAndStatus={vaccinesTypeAndStatus}
                pageIndex={paginationConf.pageIndex}
                setPageIndex={(pageIndex) => setPaginationConf((prev) => ({ ...prev, pageIndex }))}
                pageSize={paginationConf.pageSize}
                setPageSize={(pageSize) => setPaginationConf((prev) => ({ ...prev, pageSize }))}
                pageCount={pageCount}
                totalDataCount={totalDataCount}
                listIsLoading={showChildrenLoading}
                pageChanged={(pageIndex, pageSize) => {
                  if (paginationConf.pageIndex !== pageIndex || paginationConf.pageSize !== pageSize) {
                    setPaginationConf({
                      ...paginationConf,
                      pageIndex: pageIndex,
                      pageSize: pageSize,
                    });
                  }
                }}
                currentSortConfig={sortConfig}
                sortChanged={setSortConfig}
              />
            ) : (
              !showChildrenLoading && <NoResultMessage noResultOrNoData={true} />
            )}
          </main>
        </section>
        <MainFooter />
      </main>

      {showResendMessageModal && (
        <ResendMessageModal
          loading={loading}
          onCancel={() => {
            setShowResendMessageModal(false);
            setLoading(false);
          }}
          onSend={(message) => {
            sendMessageHandler(message);
          }}
        />
      )}
    </MainContainer>
  );
};

interface ModalProps {
  isOpen: boolean;
}

const LoadingModal: React.FC<ModalProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 opacity-50"></div>
      <div className="relative bg-white rounded-lg p-4">
        <div className="flex flex-1 items-center justify-center">
          <CircularProgress size={64} style={{ color: "#11589a" }} />
        </div>
      </div>
    </div>
  );
};

export default Children;
