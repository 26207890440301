import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import DropdownWithCheckbox, { DropdownType } from "../../../UI/DropdownWithCheckbox";
import IdValueType from "../../../../types/IdValueType";
import { convertReceiverDeliveryStatusToDDItem } from "../../messaging/MsgModel";

export type MessageHistoryFilterBarType = {
  statuses: IdValueType[];
  dateFrom: Date | null;
  dateTo: Date | null;
};

const allItem: IdValueType = { id: "", name: "", type: [] };

const MessageHistoryFilterBar: React.FC<{
  applyHandler: (filter: MessageHistoryFilterBarType) => void;
  resetHandler: () => void;
  statusValue: IdValueType[];
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
}> = ({ applyHandler, resetHandler, statusValue, dateValue }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [statuses, setStatuses] = useState<IdValueType[]>([]);
  const [applyIsClicked, setApplyIsClicked] = useState(false);

  let messageStatusesArray = convertReceiverDeliveryStatusToDDItem([allItem]);

  useEffect(() => {
    setStatuses(statusValue);
  }, [statusValue]);

  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  return (
    <div className="w-95/100 flex flex-wrap justify-between gap-x-6 mx-auto">
      <div className="flex flex-wrap gap-x-6 gap-y-1">

        <div>
          <label className="text-sm text-black41">{t("status")}</label>
          <DropdownWithCheckbox
            type={DropdownType.PRIMARY}
            extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
            onOptionSelected={(option) => {
              setStatuses(option);
            }}
            keys={messageStatusesArray}
            selectedKey={statuses}
            getValueByKey={(key) => {
              if (key === "") return t("all");
              return messageStatusesArray.find((msgStatus) => msgStatus.name === key)?.name ?? "";
            }}
          />
        </div>

        <div>
          <label className="text-sm text-black41">{t("send_time")}</label>
          <div className="flex gap-x-3" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>

      </div>
      <div className="flex flex-grow justify-end mt-5 gap-x-6">
        {applyIsClicked &&
          (
            statuses !== null ||
            date.dateFrom !== null ||
            date.dateTo !== null) && (
            <button
              onClick={() => {
                resetHandler();
                setStatuses([]);
                setDate({ dateFrom: null, dateTo: null });
                setApplyIsClicked(false);
              }}
              className="text-blue-primary hover:font-semibold text-sm my-auto"
            >
              {t("reset")}
            </button>
          )}

        <Button
          type={ButtonTypes.PRIMARY}
          onClick={() => {
            setApplyIsClicked(true);
            applyHandler({
              statuses: statuses,
              dateFrom: date.dateFrom,
              dateTo: date.dateTo,
            });
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default MessageHistoryFilterBar;
